import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  height: 80vh;
  align-items: center;
  justify-content: center;
`;

const Loading = () => (
  <Container>
    <p>Loading content...</p>
  </Container>
);

export default Loading;
