import calculatorReactDisplay from '../../../Images/Projects/calculator-react/display.jpg';

export default `
<h3 style="text-align: center;">
  Find the source on <a href="https://github.com/JordanDChappell/calculator-react">GitHub</a>
</h3>
<p>
  This calculator application is my take on an often recommended learning project for web developers. I wanted to
  work on a short and simple application in my spare time that would be easy to see through to the end. Most of the
  development time for the calculator was spent designing and implementing a faux analog display for my calculator
  using 16 'segments' drawn in a grid layout.
</p>
<h3>Display</h3>
<p>
  The calculator was built from the top down (visually), starting with the above mentioned display. 
</p>
<img
  alt="Calculator display"
  src="${calculatorReactDisplay}"
  class="image"
>
<p>
  I wanted to build a simple React component that could render a variety of characters (symbols) in an old-school
  electronic looking display, and so I got to work.
</p>
<p>
  The component was initially built as a 
  <a href="https://github.com/JordanDChappell/calculator-react/blob/main/codebase/src/Components/Calculator/Screen/SevenSegmentedDisplay.js">seven-segmented display.</a>
  (an electronic display with 7 separate segments that can light up individually.
  The component would take a given symbol or character as props, look up which segments should be shown, and then draw a collection of 
  <code>div</code> elements in a <code>grid</code> layout corresponding to each segment that should 'light up'.
  After working through this component I was able to render some fairly pretty looking numbers (0-9) and a '-' operator 
  but (through my short-sightedness) lacked the capability to display '+', '/', or '*' operators.
</p>
<p>
  In order to render diagonal segments for the '/' and '*' operators, an additional 4 segments are required, taking us to an
  <a href="https://github.com/JordanDChappell/calculator-react/blob/main/codebase/src/Components/Calculator/Screen/ElevenSegmentDisplay.js">eleven-segmented</a>
  display implementation.
  To achieve this I simply recycled the code for the seven segmented display and added these new diagonal segments 
  using some CSS rotations and voila, I could render everything that I would need for a calculator (except
  the pesky '+' operator).
</p>
<p>
  To render the final operator I needed to add another 4 segments in the centre of the previous display, and so the
  <a href="https://github.com/JordanDChappell/calculator-react/blob/main/codebase/src/Components/Calculator/Screen/SixteenSegmentDisplay.js">sixteen-segmented</a>
  display was born.
  This initially posed a challenge with my existing <code>grid</code> layout but was much easier to handle after I learned about the CSS
  <code>grid-template-areas</code> property. At this stage I decided to add a '.' which could light up as part of the
  display in order to achieve decimal numbers.
</p>
<h3>A calculator is born</h3>
<p>
  After spending so much time on the individual display elements I began working on the 'physical' calulator components.
  I wrote a React component to render a number of the segmented displays in a row with some CSS and logic to handle overflow in the x-axis.
  I also added single and double width button components as well as a wrapper component to display all of my required
  buttons in yet another <code>grid</code> layout.
</p>
<p>
  I also added a blinking cursor which points to the current position in the expression the user is writing.
  The cursor can move back and forward through the expression in order to fix mistakes or edit values on the fly.
</p>
<p>
  Finally I wrote up some code to handle symbol replacement (when the user moves the cursor and enters a new character),
  keyboard shortcuts, and the all important expression evaluation (which happens when the user clicks the '=' operator)
  using the suggestedly unsafe JavaScript 
  <a href="https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/eval"><code>eval</code></a>
  function. Using <code>eval</code> meant that I didn't need to implement an expression tree (or handle the expression
  dynamically), I could simply take the list of characters that the user had entered, <code>join</code> them together
  into a string, and pass that string into the <code>eval</code> function.
</p>
<p>
  The final product is a subjectively pretty looking calculator that is reminiscent of an actual calculator device, which you
  can <a href="https://jordanchappell.com/calculator-react">try here.</a>
</p>
`;
