import jordantrisThumbnail from '../Images/Projects/jordantris/thumbnail.jpg';
import jordantrisProjectContent from './Projects/jordantris/content';

import parcelReactThumbnail from '../Images/Projects/react-parcel-template/thumbnail.jpg';
import parcelReactProjectContent from './Projects/react-parcel-template/content';

import calculatorReactThumbnail from '../Images/Projects/calculator-react/thumbnail.jpg';
import calculatorReactContent from './Projects/calculator-react/content';

import sshChatServerThumbnail from '../Images/Projects/node-chat-server/thumbnail.jpg';
import sshChatServerContent from './Projects/node-chat-server/content';

export const projectList = [
  {
    id: 'jordantris',
    thumbnailUrl: jordantrisThumbnail,
    title: 'Jordantris',
    subtitle:
      'A learning experiment, Jordantris is a Tetris clone built with vanilla JavaScript.',
  },
  {
    id: 'react-parcel-template',
    thumbnailUrl: parcelReactThumbnail,
    title: 'React + Parcel Template',
    subtitle:
      'Repository template to jumpstart simple React projects using the Parcel JavaScript bundler.',
  },
  {
    id: 'calculator-react',
    thumbnailUrl: calculatorReactThumbnail,
    title: 'React Calculator',
    subtitle:
      'React based calculator application with focus on an analogue rendered screen.',
  },
  {
    id: 'node-chat-server',
    thumbnailUrl: sshChatServerThumbnail,
    title: 'Node Chat Server',
    subtitle:
      'An SSH based chat application that can be used in your favourite terminal.',
  },
];

export const projectData = {
  jordantris: {
    title: 'Jordantris',
    date: '2021-09-17T09:00:00',
    content: jordantrisProjectContent,
  },
  reactparceltemplate: {
    title: 'React + Parcel Template',
    date: '2021-09-17:13:45:00',
    content: parcelReactProjectContent,
  },
  calculatorreact: {
    title: 'React Calculator',
    date: '2022-06-06:12:54:00',
    content: calculatorReactContent,
  },
  nodechatserver: {
    title: 'Node Chat Server',
    date: '2023-02-18:09:19:00',
    content: sshChatServerContent,
  },
};
