export default `
<h3 style="text-align: center;">
  Find the source on <a href="https://github.com/JordanDChappell/jordantris">GitHub</a>
</h3>
<p>
  A take on the classic block shifting puzzler, Jordantris was created in order to
  increase my knowledge of client side application development. I have always had an
  interest in game development, but have never dedicated any real amount of time or
  effort into learning it. My decision to create a Tetris clone came about for two
  main reasons, I like Tetris, and I felt that the controls and logic were simple
  enough to implement easily in my own Javascript game engine (of sorts).
</p>
<p>
  The game loop heavily utilizes the <code>requestAnimationFrame</code> Javascript API, and all
  of the rendering is handled using two HTML canvas objects. I have attempted to
  abstract out some common components to handle drawing to the canvas, inputs, block
  collision and movement, and game logic.
</p>
<p>
  Throughout the project I was able to increase my knowledge in the following areas:
</p>
<ul>
  <li>Webpack</li>
  <li>Vanilla Javascript with ES6 modules</li>
  <li>HTML5 game development</li>
  <li>Algorithms and data structures</li>
</ul>
<p>
  I had never had the pleasure of configuring webpack myself, which usually left me
  scratching my head if I ever had to delve into it's settings on other projects. I had
  also never developed a game before and it seems that Javascript/HTML is pretty powerful
  for creating simple interactive experiences.
</p>
<p>
  <a href="https://jordanchappell.com/jordantris">Click here to see Jordantris in action.</a>
</p>`;
