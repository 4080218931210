import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { getProjectById } from '../../Utils/Requests';

const Title = styled.h1`
  text-align: center;
`;

const ProjectContent = () => {
  const [projectData, setProjectData] = useState('');
  const { projectId } = useParams();

  useEffect(() => {
    // fetch content from the server
    getProjectById(projectId)
      .then((data) => {
        setProjectData(data);
      })
      .catch((err) => console.error(err));
  }, []);

  return (
    <>
      <Title id="project-title">{projectData.title}</Title>
      <article
        id="project-content"
        dangerouslySetInnerHTML={{ __html: projectData.content }}
      ></article>
    </>
  );
};

export default ProjectContent;
