import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

/* Components */
import ProjectCard from './ProjectCard';

const FlexGrid = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: 2rem;
`;

const ProjectGrid = ({ projects }) => (
  <FlexGrid>
    {projects.map((project) => (
      <ProjectCard
        key={project.id}
        id={project.id}
        imageUrl={project.thumbnailUrl}
        title={project.title}
        subtitle={project.subtitle}
      />
    ))}
  </FlexGrid>
);

ProjectGrid.propTypes = {
  projects: PropTypes.array,
};

export default ProjectGrid;
