import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import formatUtcDateString from '../../Utils/DateUtils';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${(props) => props.theme.cardBackground};
  border-radius: 4px;
  box-shadow: 4px 4px 10px ${(props) => props.theme.cardText}90;
  color: ${(props) => props.theme.cardText};
  cursor: pointer;
  :hover {
    h3 {
      color: ${(props) => props.theme.accents};
    }
  }
`;
const ThumbnailContainer = styled.div`
  display: flex;
  padding: 0.5rem;
  align-content: center;
  align-items: center;
`;
const Thumbnail = styled.img`
  border-radius: 4px;
  box-shadow: 4px 4px 10px ${(props) => props.theme.cardText}90;
  width: 20vw;
  height: 20vw;

  @media (min-width: 992px) {
    width: 12vh;
    height: 12vh;
  }
`;
const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-content: space-between;
  padding: 0.5rem;
`;
const Title = styled.h3`
  margin: 0;
  font-weight: normal;
  font-size: 1.5rem;
  color: inherit;
  text-decoration: none;
`;
const Date = styled.p`
  font-size: 0.8rem;
  margin-top: auto;
`;

const ArticleCard = ({
  id,
  thumbnailUrl,
  title,
  subtitle,
  dateWrittenString,
}) => {
  const history = useHistory();

  return (
    <Container onClick={() => history.push(`/blog/${id}`)}>
      <ThumbnailContainer>
        <Thumbnail
          src={thumbnailUrl}
          alt={`${title} thumbnail image`}
        ></Thumbnail>
      </ThumbnailContainer>
      <TextContainer>
        <Title>{title}</Title>
        <p>{subtitle}</p>
        <Date>{formatUtcDateString(dateWrittenString)}</Date>
      </TextContainer>
    </Container>
  );
};

ArticleCard.propTypes = {
  id: PropTypes.string.isRequired,
  thumbnailUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  dateWrittenString: PropTypes.string.isRequired,
};

export default ArticleCard;
