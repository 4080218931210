import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

/* Components */
import ArticleCard from './ArticleCard';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const FlexList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2rem;

  @media (min-width: 992px) {
    width: 70&;
  }
`;

const ArticleList = ({ articles }) => (
  <Container>
    <FlexList>
      {articles.map((article) => (
        <ArticleCard
          key={article.id}
          id={article.id}
          thumbnailUrl={article.thumbnailUrl}
          title={article.title}
          subtitle={article.subtitle}
          dateWrittenString={article.date}
        />
      ))}
    </FlexList>
  </Container>
);

ArticleList.propTypes = {
  articles: PropTypes.array,
};

export default ArticleList;
