import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Heading = styled.h1`
  font-size: 2.4rem;
  font-weight: bold;
  text-align: center;
`;

const PageHeading = ({ text }) => <Heading>{text}</Heading>;

PageHeading.propTypes = {
  text: PropTypes.string.isRequired,
};

export default PageHeading;
