import React, { useEffect, useState } from 'react';
import { getListOfProjects } from '../Utils/Requests';

/* Components */
import PageHeading from '../Components/Layout/PageHeading';
import ProjectGrid from '../Components/Projects/ProjectGrid';

const Projects = () => {
  const [projectList, setProjectList] = useState([]);

  useEffect(() => {
    getListOfProjects()
      .then((data) => setProjectList(data))
      .catch((err) => console.error(err));
  }, []);

  return (
    <>
      <PageHeading text="Projects" />
      <ProjectGrid projects={projectList} />
    </>
  );
};

export default Projects;
