import React, { useEffect, useState } from 'react';
import { getListOfArticles } from '../Utils/Requests';

/* Components */
import PageHeading from '../Components/Layout/PageHeading';
import ArticleList from '../Components/Blog/ArticleList';

const Blog = () => {
  const [articleList, setArticleList] = useState([]);

  useEffect(() => {
    getListOfArticles()
      .then((data) => setArticleList(data))
      .catch((err) => console.error(err));
  }, []);

  return (
    <>
      <PageHeading text="Blog" />
      <ArticleList articles={articleList} />
    </>
  );
};

export default Blog;
