import React from 'react';
import styled from 'styled-components';

/* Components */
import PageHeading from '../Components/Layout/PageHeading';

const Blurb = styled.p`
  font-size: 1.3rem;
  text-align: center;
`;

const Music = () => (
  <>
    <PageHeading text="Music" />
    <Blurb>🚧 Under Construction. 👷‍♂️</Blurb>
  </>
);

export default Music;
