export default `
<p>
  <b>UI testing</b> is a well documented topic in the world of front-end software development and is an
  excellent candidate for automation. In this post I will discuss some of my approach to automated testing,
  the areas that benefit most from automated processes, some of the shortcomings of different approaches to the task,
  and the technology that you can use to automate test steps in your application.
</p>

<h2>My Ideas</h2>
<p>
  The precision of UI tests can vary wildly depending on the kinds of problems that you are looking to solve. You can
  implement steps to ensure a button press has the intended effect, or that clicking a link directs the user to the
  correct location, this is a <b>functionality regression</b> test. It's possible to test that component layout is 
  unchanged between versions of an application, comparing screenshots or snapshots of components, testing for ordering
  in lists, checking text in headers, this is a <b>component regression</b> test.
</p>

<h2>Functionality Regression</h2>
<p>
  <ul>
    <li>Break down the UI into functions to be tested, this could be:</li>
    <ul>
      <li>Ensure links navigate to expected location.</li>
      <li>Check input validation on a form.</li>
      <li>Guarantee data requests complete in satisfactory time.</li>
    </ul>
    <li>Document test steps required for a basic cover of most functions in the app.</li>
    <li>Begin to automate these tasks.</li>
  </ul>
</p>

<h2>Component Regression</h2>
<p>
  <ul>
    <li>Break down UI into components to be tested.</li>
    <li>Determine the level of testing a component requires:</li>
    <ul>
      <li>Should text remain the same for this component?</li>
      <li>Will the order of elements in the component need monitoring?</li>
      <li>Does the component need pixel precise comparisons?</li>
    </ul>
    <li>Note the necessary steps for a manual test of the component.</li>
    <li>Complete this test manually and ensure satisfactory coverage.</li>
    <li>Use manual testing steps to automate the task.</li>
  </ul>
</p>

<h2>The Shortcomings</h2>
<p>
  <b>Acceptance testing</b>, also known as user acceptance testing (UAT), is the process of ensuring a feature or change
  is fit for use, usually performed by QA, a stakeholder, real world user, or other invested party.
  When automating test cases for your application it is important to remember that you should not be aiming to replace
  at least some manual testing steps for anything but a regression of existing features.
</p>
<p>
  <b>Test maintainence</b> is also a factor when deciding to implement your own testing framework using .NET tech. If
  your application is ever changing, or does not feel like it is in a final state with regards to user interface, it
  can be hard to balance the level of detail with which your tests should be written. If you are constantly required to
  spend time updating automated processes, you may not find many benefits to them.
</p>
<p>
  <b>Testability</b> of your application may also be a concern, how easy will it be for you to find elements on the page?
  Do your elements have constant values for position, even at different device size breakpoints? Do you use a user interface
  framework which reduces your ability to provide unique identifiers for elements under test? These kinds of questions
  can help to set a realistic expectation of the level of coverage that is possible with automated test processes.
</p>

<h2>Available Technology</h2>
<p>
  If you've gotten to this point in the article and are worried about the overheads involved with maintainence and
  implementation of tests, then you're in luck. There are a plethora of services that already exist to handle the automation
  of UI tests for your application, here's a quick list of some that I've used before:
  <ul>
    <li><a href="https://www.browserstack.com/automate">BrowserStack Automate</a></li>
    <li><a href="https://www.katalon.com/">Katalon Studio</a></li>
    <li><a href="https://www.telerik.com/teststudio">Telerik Test Studio</a></li>
  </ul>
</p>
<p>
  As with all third party services, you'll likely end up paying a subscription to record and run tests, and pricing
  may increase with the size of your team.
</p>
<p>
  If the process of implementing and maintaining a test framework feels like something you're up for (or a fun
  project), then look no further than <a href="https://www.selenium.dev/">Selenium</a>, <a href="https://appium.io/">Appium</a>, 
  and the <a href="https://www.nuget.org/packages/Selenium.WebDriver/3.141.0">Selenium.WebDriver package</a>.
</p>
<p>
  <b>Selenium</b> is an open-source automated testing framework which consists of a handful of tools useful for developing
  automated regression tests for applications on the web. Selenium WebDriver is a tool which can take control of a device
  or web-browser and interact with UI elements in similar ways to that of a real world user.
</p>
<p>
  <b>Appium</b> is the native mobile and desktop extension to Selenium. With Appium it is possible to automated native
  Android, iOS, and Window applications on local or even remote machines. You may have noticed that BrowserStack
  offer a solution for connecting and sending commands to real mobile devices for those who like to test on platforms
  and devices that they don't have physical access to.
</p>
<p>
  <b>Selenium.WebDriver package</b> is available as a driver interface for C# and allows the writing of robust tests
  in a language familiar to .NET developers. There are similar packages for a handful of programming languages, including
  Java, PHP, Ruby, and Python.
</p>
<p>
  With this small handful of tools it is possible to create a full suite of automated tests and tools for your own
  requirements and application. Some useful features include:
  <ul>
    <li>Screenshots for manual confirmation of problems.</li>
    <li>Pixel perfect comparisons of UI elements.</li>
    <li>Headless browser testing, for automation on CI build agents (self hosted or in the cloud).</li>
  </ul>
</p>

<br>

<em>
  Look forward to part 2 in this series for a shallow dive into setting up an automated testing project in .NET for a
  web application using Selenium.
</em>
`;
