import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faMoon } from '@fortawesome/free-solid-svg-icons';

const Button = styled.button`
  height: 2rem;
  width: 4rem;
  border: none;
  border-radius: 1rem;
  position: relative;
  color: ${(props) => props.theme.background};
  background-color: ${(props) => props.theme.accents};
  font-size: 1rem;
  text-decoration: none;
  justify-content: center;
  :hover {
    cursor: pointer;
  }
`;
const Icon = styled.span`
  height: 100%;
  width: 100%;
`;
const Overlay = styled.div`
  position: absolute;
  height: 1.4rem;
  width: 1.5rem;
  border-radius: 50%;
  background-color: ${(props) => props.theme.background};
  transition: all 0.3s;
  top: 0.3rem;
  left: 0.325rem;
  ${(props) =>
    props.toggled &&
    css`
      left: 2.15rem;
    `};
`;

const ThemeToggler = ({ isToggled, style, onClick }) => (
  <Button onClick={onClick} style={style}>
    <Overlay toggled={isToggled} />
    <Icon aria-label="Theme switcher" role="img">
      <FontAwesomeIcon icon={faMoon} style={{ width: '50%' }} />
      <FontAwesomeIcon icon={faSun} style={{ width: '50%' }} />
    </Icon>
  </Button>
);

ThemeToggler.propTypes = {
  isToggled: PropTypes.bool,
  style: PropTypes.object,
  onClick: PropTypes.func.isRequired,
};

export default ThemeToggler;
