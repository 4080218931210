import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { useTheme } from 'styled-components';

const linkStyle = {
  textDecoration: 'none',
  color: 'inherit',
  fontWeight: '550',
};

const CustomNavLink = ({ path, exact, children }) => {
  const theme = useTheme();
  const activeStyle = {
    color: theme.accents,
  };

  return (
    <NavLink
      exact={exact}
      to={path}
      activeStyle={activeStyle}
      style={linkStyle}
    >
      {children}
    </NavLink>
  );
};

CustomNavLink.propTypes = {
  path: PropTypes.string.isRequired,
  exact: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default CustomNavLink;
