import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import formatUtcDateString from '../../Utils/DateUtils';
import { getBlogArticleById } from '../../Utils/Requests';

const Header = styled.div`
  display: flex;
`;
const HeaderImage = styled.img`
  border-radius: 4px;
  box-shadow: 4px 4px 10px ${(props) => props.theme.cardText}90;
  margin-left: auto;
  margin-top: 1.7rem;
  height: 18vw;
  width: 18vw;

  @media (min-width: 992px) {
    width: 12vh;
    height: 12vh;
  }
`;
const Date = styled.p`
  font-size: 0.8rem;
`;

const ArticleContent = () => {
  const [articleData, setArticleData] = useState('');
  const { articleId } = useParams();

  useEffect(() => {
    // fetch content from the server
    getBlogArticleById(articleId)
      .then((data) => {
        setArticleData(data);
      })
      .catch((err) => console.error(err));
  }, []);

  return (
    <>
      <Header>
        <div>
          <h1 id="article-title">{articleData.title}</h1>
          <Date id="article-date-published">
            {formatUtcDateString(articleData.date)}
          </Date>
        </div>
        <HeaderImage
          alt={`${articleData.title} header image`}
          src={articleData.headerImageUrl}
        ></HeaderImage>
      </Header>
      <hr></hr>
      <article
        id="article-content"
        dangerouslySetInnerHTML={{ __html: articleData.content }}
      ></article>
    </>
  );
};

export default ArticleContent;
