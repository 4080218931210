import webUiTesting1Thumbnail from '../Images/Blog/web-ui-testing-1/thumbnail.jpg';
import webUiTesting1Header from '../Images/Blog/web-ui-testing-1/header.jpg';
import webUiTesting1Content from './Blog/web-ui-testing-1/content';

import webApiBestPracticesImage from '../Images/Blog/web-api-best-practices/thumbnail.png';
import webApiBestPracticesContent from './Blog/web-api-best-practices/content';

export const articleList = [
  {
    id: 'web-ui-testing-1',
    thumbnailUrl: webUiTesting1Thumbnail,
    title: 'Automated UI Testing in .NET',
    subtitle: 'An intro to UI testing in the .NET stack.',
    date: '2021-09-14T07:35:00',
  },
  {
    id: 'web-api-best-practices',
    thumbnailUrl: webApiBestPracticesImage,
    title: 'Web (HTTP) API Best Practices',
    subtitle: 'My best practices for simple RESTful APIs.',
    date: '2023-05-03T08:11:00',
  },
];

export const articleData = {
  webuitesting1: {
    title: 'Automated UI Testing (in .NET)',
    date: '2021-09-14T07:35:00',
    headerImageUrl: webUiTesting1Header,
    content: webUiTesting1Content,
  },
  webapibestpractices: {
    title: 'Web (HTTP) API Best Practices',
    date: '2023-05-03T08:11:00',
    headerImageUrl: webApiBestPracticesImage,
    content: webApiBestPracticesContent,
  },
};
