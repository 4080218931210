import { projectData, projectList } from '../Data/ProjectData';
import { articleData, articleList } from '../Data/ArticleData';

export const getListOfProjects = () =>
  new Promise((resolve) => {
    resolve(projectList);
  });

export const getProjectById = (projectId) =>
  new Promise((resolve) => {
    resolve(projectData[projectId.replaceAll('-', '')]);
  });

export const getListOfArticles = () =>
  new Promise((resolve) => {
    resolve(articleList);
  });

export const getBlogArticleById = (blogId) =>
  new Promise((resolve) => {
    resolve(articleData[blogId.replaceAll('-', '')]);
  });
