import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  html {
    font-family: 'Open Sans', Sans-Serif;
    font-size: 14px;
    font-weight: 500;
    background-color: ${(props) => props.theme.background};
    color: ${(props) => props.theme.text};
    transition: background-color ${(props) => props.theme.colorTransition};

    @media (min-width: 786px) {
      font-size: 20px;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Courier New', Courier, monospace;
  }

  ul {
    margin: 0;
    padding: 0 1.2rem;
  }
  li:not(:last-child) {
    padding-bottom: 0.8rem;
  }
  ul ul li {
    padding-bottom: 0.8rem;
  }
  
  a {
    color: inherit;
    transition: color ${(props) => props.theme.colorTransition};
    :hover {
      color: ${(props) => props.theme.accents};
    }
  }

  hr {
    border: 2px solid ${(props) => props.theme.text};
    border-radius: 2px;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  pre {
    background-color: ${(props) => props.theme.codeBackground};
    color: ${(props) => props.theme.codeText};
    border-radius: 0.3rem;
    hyphens: none;
    tab-size: 2;
    white-space: pre-wrap;
    word-break: normal;
  }
  pre code {
    background-color: inherit;
    overflow-x: auto;
    display: block;
    max-width: 100%;
    min-width: 100px;
    line-height: 1.75;
    border-radius: 0;
    padding: 0;
  }
  code {
    font-family: 'Cascadia Code', sans-serif;
    color: ${(props) => props.theme.codeText};
    background-color: ${(props) => props.theme.codeBackground};
    border-radius: 0.25rem;
    padding: 0.15rem;
    font-size: 0.8em;
  }

  .image {
    border-radius: 4px;
    box-shadow: 4px 4px 10px ${(props) => props.theme.cardText}90;
    max-width: 100%;
  }
  .theme-width {
    width: 100%;

    @media (min-width: 786px) {
      width: 50%;
    }
  }

  article p, 
  article pre, 
  article ul {
    margin-top: 1.6rem;
    margin-bottom: 0;
  }

  article h1,
  article h2,
  article h3 {
    margin-top: 2rem;
    margin-bottom: 0;
  }
`;

export default GlobalStyles;
