import React from 'react';

/* Components */
import PageHeading from '../Components/Layout/PageHeading';

const Home = () => (
  <article>
    <PageHeading text="About Me" />
    <p>👋 G&apos;day, I&apos;m Jordan.</p>
    <p>
      🌏 I live on the east coast of Australia in a beautiful city called
      Newcastle.
    </p>
    <p>💼 I am a software developer.</p>
    <h2>Professional</h2>
    <p>
      I work as a the &apos;Lead Web Developer&apos; for an amazing Newcastle
      based company; <a href="https://cordel.ai">Cordel</a>. We deploy Lidar
      based solutions to geospatial surveying, asset management, and gauging
      problems in locomotive and UAV based environments. Most of my time is
      spent developing web based applications used to visualise and organise the
      huge amount of data that we capture for our rail sector clients. We also
      develop Windows applications that pair with our hardware offerings in the
      UAV space.
    </p>
    <h2>Personal</h2>
    <p>
      This site is intended to be a simple and fun place to show off projects
      that I&apos;ve worked on, things that I find interesting, as well as a
      space for me to share ideas and thoughts that I deem worthy enough to send
      out into the internet.
    </p>
    <p>
      Though much of my downtime from work is spent away from software
      development I do attempt to be creative and learn from other&apos;s
      creativity as a way to decompress, learn, and engage with others, while
      outputting something to show as my own.
    </p>
    <p>
      ⏳ If you&apos;ve made it to this page, please take some time to have a
      look at some of the stuff I&apos;ve worked.
    </p>
  </article>
);

export default Home;
