import parcelReactHeader from '../../../Images/Projects/react-parcel-template/react-plus-parcel.jpg';
import parcelReactUseThisTemplate from '../../../Images/Projects/react-parcel-template/use-this-template.jpg';

export default `
<h3 style="text-align: center;">
  Find and clone the template repository on <a href="https://github.com/JordanDChappell/react-app-template">GitHub</a>
</h3>
<div style="text-align:center;">
  <img
    alt="React Parcel logo image"
    src="${parcelReactHeader}"
    class="image theme-width"
  ></img>
</div>
<p>
  My personal and highly opinionated project template for an application built using React and Parcel. Includes useful
  setup for linting with ESLint, code style enforcement with Prettier, unit testing with Jest, and some helpful
  parcel plugins out of the box.
</p>
<p>
  Parcel is an extemely light-weight, low configuration code bundler that comes ready for most JavaScript workflows out
  of the box. It comes with pre-configured support for JavaScript, CSS, HTML, TypeScript, CoffeeScript, SCSS, LESS, Stylus,
  and more. Anything that isn't supported at the get-go or through configuration can easily be included as a plugin, either
  an existing solution or with some custom code.
</p>
<p>
  The repository template is fairly opinionated about technology, directory structure, package management, and VSCode
  configuration. If you'd like to give it a try, simply navigate to the repository in GitHub and click the 'Use this
  template' button.
</p>
<div style="text-align:center;">
  <img
    alt="Use this template button location"
    src="${parcelReactUseThisTemplate}"
    class="image"
    width="100%"
  >
  </img>
</div>
<p>
  Simply follow the prompts in GitHub to create a new repository using my template as a base, take some care to look
  over the included <code>README.md</code> file for some pointers to the files that require changes before getting started.
</p>
`;
