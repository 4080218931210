import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const PageContainer = styled.div`
  margin: 0;
  display: grid;
  place-items: center;
`;

const SiteWrapper = ({ children }) => <PageContainer>{children}</PageContainer>;

SiteWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default SiteWrapper;
