export default `
<h3 style="text-align: center;">
  Check out a .NET 7 minimal API example on my <a href="https://github.com/JordanDChappell/dotnet-minimal-api-examples">GitHub</a>
</h3>
<p>
  Generally a well designed public API will:
<ul>
  <li>Expose methods with well defined names</li>
  <li>Implement a consistent response structure</li>
  <li>Utilise HTTP actions</li>
  <li>Provide meaningful responses</li>
  <li>Avoid side effects</li>
  <li>Implement version management</li>
  <li>Provide a specification for clients to consume</li>
</ul>
<p>
  Let's take a look at some of my own opinionated best practices, along with examples of how they can benefit consumers 
  of your own API below. In the name of simplicity let's assume that your web service recieves and provides <code>JSON</code>
  data.
</p>

<h2>1. Well defined method names</h2>
<p>
  One of the first pieces of information that a consumer will notice is the list of endpoints that an API exposes. These
  endpoints can be thought of as public method names for the API. A good method name will:
</p>
<ul>
  <li>Describe it's resource</li>
  <li>Describe it's intent</li>
  <li>Avoid ambiguous uses</li>
</ul>
<p>
  In a simple RESTful API the first (left-most) section of the path should be used to describe a resource, followed by
  parameters or resource identifiers, e.g. <code>/{resourceName}/{resourceIdentifier}/{childResourceName}</code>.
</p>
<p>
  Here is an endpoint that is designed to retrieve a list of todo items from a system. We could define this
  route in the following ways:
</p>
<pre>
  <code>  🔴 [GET] /get/todo/list
  🟢 [GET] /todos</code>
</pre>
<p>
  The first (bad) example does describe it's intent well, though routes with names like this do not expose the current 
  resource in an obvious way and may create ambiguity with other routes.
</p>
<p>
  The second (good) example is simple, describes the current resource, and leads to much more organic resource accessor
  endpoints for other use cases. Consider the following additional endpoint that is designed to retrieve a particular 
  todo item by it's unique resource identifier:
</p>
<pre>
  <code>  🔴 [GET] /get/todo/{identifier}
  🟢 [GET] /todos/{identifier}</code>
</pre>
<p>
  The first example is starting to show ambiguity in it's path structure, is <code>/list</code> an idenfitier or an 
  action?
</p>

<h2>2. Consistent response structure</h2>
<p>
  A thoughtful structure for responses will make an API easier to consume and maintain. Being familiar with <code>JSON
  </code> can be important when defining models that represent resources in the system. As an extreme example check out 
  the response bodies below.
</p>
<h4>Flat array structure</h4>
<pre>
  <code>  [
    {
      "identifier": 1,
      "name": "Work on project",
      "status": "Todo"
    },
    {
      "identifier": 2,
      "name": "Write an article",
      "status": "InProgress"
    }
  ]</code>
</pre>
<h4>Nested property structure</h4>
<pre>
  <code>  {
    "items": [
      {
        "identifier": 1,
        "name": "Work on project",
        "status": "Todo"
      },
      {
        "identifier": 2,
        "name": "Write an article",
        "status": "InProgress"
      }
    ]
  }</code>
</pre>
<p>
  The first example is fine <b>UNTIL</b> you need to add more information to the response body, like a <code>count</code>
  property, or <code>page</code> information (if the data grows to require pagination). E.g.
</p>
<pre>
  <code>  {
    "items": [...],
    "count": 1000,
    "page": 1,
    "pageSize": 100,
    "pages": 10
  }</code>
</pre>
<p>
  It can also be important to provide consistent response across resource types, below we have a todo item and a project 
  resource.
</p>
<pre>
  <code>  {
    "identifier": 1,
    "name": "Work on project",
    "status": "Todo"
  }</code>
</pre>
<pre>
  <code>  {
    "Id": "abc",
    "Name": "calculator",
    "Status": 2
  }</code>
</pre>
<p>
  Using inconsistent property names, datatypes, or casing adds complexity to your methods as well as the code that consumers 
  will need to write or scaffold in order to interactive with your service.
</p>

<h2>3. Utilise HTTP actions (verbs)</h2>
<p>
  HTTP defines a set of verbs to indicate different actions that can be performed on a resource (see this 
  <a href="https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods" target="_blank">MDN</a> for more information).
  Utilising the four most common verbs is crucial in a responsible HTTP based web API implementation. 
</p>
<ul>
  <li><code>GET</code>: Retrieve a representation of a resource</li>
  <li><code>PUT</code>: Replace an entire resource with a new representation</li>
  <li><code>POST</code>: Submit / create a new resource</li>
  <li><code>DELETE</code>: Remove the specified resource</li>
</ul>
<p>
  Below is an example of a service that is utilising HTTP actions correctly:
</p>
<pre>
  <code>  [GET] /todos/{identifier}
  [PUT] /todos/{identifier}
  [POST] /todos
  [DELETE] /todos/{identifier}</code>
</pre>
<p>
  And now a service that seems to be unaware that HTTP actions exist:
</p>
<pre>
  <code>  [GET] /todos/{identifier}
  [GET] /todos/{identifier}/update
  [GET] /todos/create
  [GET] /todos/{identifier}/delete</code>
</pre>
<p>
  Utilising HTTP actions not only allows us to provide a more consistent experience for consumers of our services, but
  can be important when designing endpoints that accept a large amount of data. Endpoints using the <code>GET</code>
  method (in most cases) do not accept a 
  <a href="https://developer.mozilla.org/en-US/docs/Web/API/Request/body" target="_blank">request body</a>, which
  can restrict the type of data that clients can send to a service.
</p>

<h2>4. Provide meaningful responses</h2>
<p>
  We've seen how we can design easy to consume service interfaces from the point of view of accessing resources and
  conveying meaning for actions, now let's take a look at some ways to ensure our responses are easy to parse and provide
  an appropriate level of detail to clients.
</p>
<p>
  HTTP includes a large set of status codes which are a protocol supported mechanism 
  (<a href="https://developer.mozilla.org/en-US/docs/Web/HTTP/Status" target="_blank">MDN</a>)
  intended to provide contextual information about the results of an action to HTTP clients. Each code is an integer with
  a descriptive name and belongs to one of 5 classes depending on where it's value falls into each of the following ranges: 
</p>
<ul>
  <li>Informational responses (<code>100</code>&mdash;<code>199</code>)</li>
  <li>Successful responses (<code>200</code>&mdash;<code>299</code>)</li>
  <li>Redirection messages (<code>300</code>&mdash;<code>399</code>)</li>
  <li>Client error responses (<code>400</code>&mdash;<code>499</code>)</li>
  <li>Server error responses (<code>500</code>&mdash;<code>599</code>)</li>
</ul>
<p>
  <b>Note:</b> The HTTP specification does not provide values for every integer in each class range, these ranges exist
  to allow values to be added to each range in the future as required.
</p>
<p>
  Along with a response code, a HTTP service can provide additional information in it's response bodies. If an entity is
  created in the system the service could return it's representation as part of the response body sent back to the client.
  If an error has occurred then the system could provide additional context that may help the client to understand what
  went wrong.
</p>
<p>
  Error message consistency across endpoints can be achieved using the 'Problem Details'
  (<a href="https://www.rfc-editor.org/rfc/rfc7807" target="_blank">RFC7807</a>) standard, which is intended to be used
  as a way to send machine-readable error information as part of a HTTP response body. A standard Problem Details object
  will include:
</p>
<ul>
  <li>Type: path to a document which explains the problem in more detail</li>
  <li>Title: summary of the problem type</li>
  <li>Status: the HTTP status code</li>
  <li>Detail: an explanation of the exact error which occurred</li>
  <li>Instance: URI that represents the specific occurrence of the problem</li>
</ul>
<p>
  The Problem Details standard is designed to be extensible through 'extension members'. Extension members are simply
  extra members added in addition to the default set described above, e.g. 
  <code>{ "errors": ["'Name' is a required field"] }</code>.
</p>
<p>
  Below are some examples of good and bad responses to different client requests to our hypothetical todo service.
</p>
<h4><code>[GET] /todos/{identifier}</code></h4>
<p>Client requests a resource that does not exist.</p>
<pre>
  <code>  Status: 200
  Body: null</code>
</pre>
<p>vs.</p>
<pre>
  <code>  Status: 404
  Body: {
    "type": "http://example.com/problems/not-found",
    "title": "Not found.",
    "status": 404,
    "detail": "Requested todo item does not exist.",
    "instance": "/todos/100"
  }</code>
</pre>
<h4><code>[POST] /todos/{identifier}/complete</code></h4>
<pre>
  <code>  Status: 400
  Body: {}</code>
</pre>
<p>vs.</p>
<pre>
  <code>  Status: 400
  Body: {
    "type": "http://example.com/problems/todo-already-complete",
    "title": "Cannot set todo as complete.",
    "status": 400,
    "detail": "Todo is already marked as complete.",
    "instance": "/todos/100/complete"
  }</code>
</pre>

<h2>5. Avoid side effects</h2>
<p>
  A simple practice that will ensure that an API is easy to use and understand, developers should try to provide methods
  with a single responsibility for a single resource (atomic operations).
</p>
<p>
  This can be fairly easy to achieve in a simple service, but may become harder to manage when introducing a large number
  of distinct resources with endpoints that are provided to achieve results outside of the basic CRUD (create, read, 
  update, and delete) operations.
</p>
<h3>Example</h3>
<h4><code>[POST] /todos/getorcreate</code></h4>
<p>Body</p>
<pre>
  <code>  {
    "identifier": 100,
    "name": "Brush teeth",
    "status": "Todo"
  }</code>
</pre>
<p>Response</p>
<pre>
  <code>  Status: 201 (Created)
  Body: {
    "identifier": 100,
    "name": "Brush teeth",
    "status": "Todo"
  }</code>
</pre>
<p>
  This endpoint has very obvious side effects for demonstration purposes, the system will either retrieve the todo item
  with identifier <code>100</code> or will create a new todo with the details provided. We can clearly see that there will
  be unexpected side effects when calling this method depending on the internal state of the service. Ideally we would 
  provide two separate methods to get or create a todo item and place responsibility on the client to make a decision
  about which method is required.
</p>

<h2>6. Validate data</h2>
<p>
  Validation of parameters and input data is a simple but highly effective tool which will reduce unexpected errors in your
  service as well as allow clients to understand why an issue has occurred (as well as how to resolve that issue).
</p>
<p>
  Consider the endpoint <code>[POST] /todos</code> that expects the following properties as part of the request body:
</p>
<ul>
  <li>Name</li>
  <li>Status</li>
</ul>
<p>
  Let's say that a client creates the following request:
</p>
<pre>
  <code>  {
    "description": "Clean the car"
  }</code>
</pre>
<p>
  Our service should ensure that the request body contains valid data for the <code>Name</code> and <code>Status</code>
  fields and provide a Problem Details response:
</p>
<pre>
  <code>  Status: 400
  Body: {
    "type": "http://example.com/problems/invalid-todo",
    "title": "Invalid todo request body.",
    "status": 400,
    "detail": "Missing required fields for todo.",
    "instance": "/todos",
    "errors": [
      "Property 'Name' is required to create a todo item",
      "Property 'Status' is required to create a todo item",
    ]
  }</code>
</pre>
<p>
  If a client recieves a response with this level of detail they will be able to resolve their issue promptly.
</p>

<h2>7. Implement versioning</h2>
<p>
  Versions are something that you don't know you need until you absolutely need them. Even with all the planning in the 
  world, objects and schemas change over time. Implementing versioning from your initial release is valued as it forms
  part of the contract / specification of your service from it's inception, requiring less changes for clients when you
  do eventually need to make a breaking change.
</p>
<p>
  Versioning can be achieved in a wide variety of ways, HTTP APIs usually use one or more of the following mechanisms:
</p>
<ul>
  <li>Query parameter: <code>[POST] /todos?version=1</code></li>
  <li>Route parameter: <code>[POST] /v1/todos</code></li>
  <li>Request header value:</li>
  <pre>
    <code>  POST /todos HTTP/1.1
  Host: myservice.com
  Accept: application/json
  Version: 1</code>
</pre>
</ul>

<h2>8. Provide a specification</h2>
<p>
  RESTful HTTP APIs can use the <a href="https://spec.openapis.org/oas/v3.1.0" target="_blank">OpenAPI specification</a> 
  as a standard way to provide a specification to clients. This specification document exposes public methods with 
  descriptions of their intended use in a machine readable format which can be used to scaffold client side interfacing code.
</p>
<p>
  This document can then be hosted alongside your application and interacted with through a web browser so that clients
  can test and sandbox your endpoints without writing code or using a tool like Postman.
</p>
<p>
  See the example .NET application on my GitHub page to see how you can implement an API specification using third-party
  libraries with hardly any code required!
</p>
`;
