import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Flex = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.cardBackground};
  padding: 1rem;
  border-radius: 4px;
  box-shadow: 4px 4px 10px ${(props) => props.theme.cardText}90;
  color: ${(props) => props.theme.cardText};
  cursor: pointer;
  transition: transform 0.2s;
  :hover {
    transform: scale(1.01);
  }

  @media (min-width: 992px) {
    width: 33%;
  }
`;
const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Image = styled.img`
  border-radius: 4px;
  box-shadow: 4px 4px 10px ${(props) => props.theme.cardText}90;
  margin: 0.5rem;
  width: 12rem;

  @media (max-width: 768px) {
    width: 90%;
  }
`;
const TitleContainer = styled.div`
  padding: 0 1rem;
  text-align: center;
`;
const Title = styled.h3`
  font-size: 1.5rem;
  margin: 0.5rem 0;
`;
const Subtitle = styled.p`
  margin: 0.5rem 0;
`;

const ProjectCard = ({ id, imageUrl, title, subtitle }) => {
  const history = useHistory();

  return (
    <Flex onClick={() => history.push(`/projects/${id}`)}>
      <ImageContainer>
        <Image
          src={imageUrl}
          alt={`Preview image for ${title} project`}
        ></Image>
      </ImageContainer>
      <TitleContainer>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
      </TitleContainer>
    </Flex>
  );
};

ProjectCard.propTypes = {
  id: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default ProjectCard;
