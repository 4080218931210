const white = '#ffffff';
const codeGrey = '#343434';

const defaultThemeValues = {
  cardText: '#333333',
  codeText: white,
  codeBackground: codeGrey,
  colorTransition: '0.3s ease',
};
export const lightTheme = {
  ...defaultThemeValues,
  accents: '#367ab3',
  background: white,
  text: '#333333',
  cardBackground: white,
};
export const darkTheme = {
  ...defaultThemeValues,
  accents: '#4499e0',
  background: '#1b1b1b',
  text: '#e6e6e0',
  cardBackground: '#e6e6e0',
};
