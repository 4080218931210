import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

/* Components */
import CustomNavLink from '../Links/CustomNavLink';
import ThemeToggler from '../Buttons/ThemeToggler';

const Header = styled.header`
  position: fixed;
  height: 4rem;
  width: 95vw;
  top: 0;
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.background};
  transition: background-color ${(props) => props.theme.colorTransition};
  z-index: 5;
`;
const Nav = styled.nav`
  > a:not(:last-child) {
    margin-right: 1rem;
  }
`;

const NavBar = ({ currentTheme, toggleTheme }) => (
  <Header>
    <Nav>
      <CustomNavLink exact path="/">
        Jordan Chappell
      </CustomNavLink>
      <CustomNavLink path="/projects">Projects</CustomNavLink>
      <CustomNavLink path="/blog">Blog</CustomNavLink>
      <CustomNavLink path="/music">Music</CustomNavLink>
    </Nav>
    <ThemeToggler
      isToggled={currentTheme === 'dark'}
      onClick={toggleTheme}
      style={{ marginLeft: 'auto' }}
    />
  </Header>
);

NavBar.propTypes = {
  currentTheme: PropTypes.string,
  toggleTheme: PropTypes.func.isRequired,
};

export default NavBar;
